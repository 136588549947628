<template>
  <b-modal
    v-model="open"
    :size="size"
    v-bind="props"
  >
    <template #modal-header>
      <div v-if="!loading" class="w-100 d-flex justify-content-between align-items-start">
        <div>
          <h4 v-if="title">
            {{ title }}
          </h4>
          <bread-crumb
            v-if="breadCrumb"
            :data="breadCrumb"
            :no-route-meta="noRouteMeta"
          />
          <div
            v-if="actions.length > 0"
            class="d-flex flex-wrap align-items-center"
            :class="{'mt-50': breadCrumb || title}"
            style="gap: 8px;"
          >
            <b-button
              v-for="(action, i) in actions"
              :key="`modal-action-index-${i}`"
              size="sm"
              v-bind="action.props || {}"
              @click="action.onClick && action.onClick()"
            >
              <feather-icon
                v-if="!!action.icon"
                :icon="action.icon"
                :class="{'mr-50': action.text}"
              />
              {{ $tc(action.text) }}
            </b-button>
          </div>
        </div>
        <button
          class="flex-shrink-0"
          style="border: none; outline: none; background: none;"
          @click="$emit('update:open', false)"
        >
          <feather-icon size="18" icon="XIcon" />
        </button>
      </div>
    </template>

    <template #default>
      <slot v-if="!loading" />
      <div v-else class="d-flex flex-column align-items-center p-4">
        <b-spinner />
      </div>
    </template>

    <template #modal-footer>
      <div v-if="!loading" class="d-flex w-100 justify-content-end" :class="footerClass" style="gap: 8px;">
        <b-button
          v-if="!hideOk"
          type="button"
          variant="success"
          :disabled="okDisabled"
          @click.stop="$emit('ok-clicked')"
        >
          <feather-icon v-if="okIconPosition !== 'right'" :icon="okIcon" class="mr-50"/>
          {{ $t(okLabel) }}
          <feather-icon v-if="okIconPosition === 'right'" :icon="okIcon" class="ml-50"/>
        </b-button>
        <b-button
          type="button"
          variant="danger"
          @click.stop="$emit('update:open', false)"
        >
          <feather-icon icon="XIcon" class="mr-50"/>
          {{ $t('classification~cancel') }}
        </b-button>
      </div>
      <div v-else />
    </template>
  </b-modal>
</template>

<script>
import BreadCrumb from '../BreadCrumb/BreadCrumb.vue'

export default {
  components: {
    BreadCrumb,
  },
  props: {
    footerClass: {
      type: String,
      default: '',
    },
    open: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    breadCrumb: {
      type: Array,
      default: () => null,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    noRouteMeta: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
    },
    title: {
      type: String,
      default: '',
    },
    okLabel: {
      type: String,
      default: 'Save',
    },
    okDisabled: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
      default: () => ({}),
    },
    okIcon: {
      type: String,
      default: 'CheckIcon',
    },
    okIconPosition: {
      type: String,
      default: 'left',
    },
    hideOk: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
