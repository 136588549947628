<template>
  <fragment>
    <data-table
      url="/contradictions"
      :resolve-data="(data) => (data && data.data && data.data.data) || []"
      :columns="columns"
      :on-update-click="(row) => {}"
      delete-url="/"
      :more-table-actions="[
        {
          icon: 'RefreshCcwIcon',
          label: 'Auto-Generate contradiction point',
          onClick: autoGenerateContradictionPoints
        },
        {
          icon: 'Edit2Icon',
          label: 'Edit reduction amount',
          onClick: () => {
            isReductionAmountModalOpen = true
          }
        },
        {
          icon: 'FilePlusIcon',
          label: 'Add contradiction point',
          onClick: () => {
            isCreateContradictionPointModalOpen = true
          }
        },
      ]"
    >
      <template #beforeTableHead>
        <b-alert show variant="warning" class="p-1">
          <div
            class="d-flex align-items-center"
            style="gap: 16px;"
          >
            <feather-icon
              icon="AlertTriangleIcon"
              size="18"
            />
            <span>
              Rent retention is possible according to the contract
            </span>
          </div>
        </b-alert>
      </template>
    </data-table>
    <modal
      :open.sync="isReductionAmountModalOpen"
      :bread-crumb="[
        {
          text: 'Edit reduction amount',
          active: true,
        }
      ]"
    >
      <auto-form
        :fields="reductionAmountFields"
        :entity="reductionAmountEntity"
        no-default-cols
        title="Edit reduction amount"
        description="Change if you want to update the reduction amount values"
      />
    </modal>
    <modal
      :open.sync="isCreateContradictionPointModalOpen"
      :bread-crumb="[
        {
          text: 'Add contraction point',
          active: true,
        }
      ]"
    >
      <auto-form
        :fields="addContradictionPointFields"
        :entity="contradictionPointEntity"
        no-default-cols
        title="Add a new contradiction"
        description="Contradiction point are used to contest an invoice"
      />
    </modal>
  </fragment>
</template>

<script>
import DataTable from '@/views/app/CustomComponents/DataTable/DataTable.vue'
import { handleConfirm } from '@/views/app/CustomComponents/DataTable/state/data-table-store'
import { BAlert } from 'bootstrap-vue'
import Modal from '@/views/app/CustomComponents/Modal/Modal.vue'
import AutoForm from '@/views/app/CustomComponents/AutoForm/AutoForm.vue'
import columns from './columns'
import addContradictionPointFields from './fields/add-contradiction-point'
import reductionAmountFields from './fields/reduction-amount'

export default {
  components: {
    DataTable,
    BAlert,
    Modal,
    AutoForm,
  },
  data() {
    return {
      columns,
      contradictionPointEntity: {},
      reductionAmountEntity: {},
      addContradictionPointFields,
      reductionAmountFields,
      isReductionAmountModalOpen: false,
      isCreateContradictionPointModalOpen: false,
    }
  },
  methods: {
    autoGenerateContradictionPoints() {
      handleConfirm({
        swal: this.$swal,
        http: this.$http,
        url: '/dummy',
        method: 'post',
        body: {},
        title: 'Really want to generate contradiction points?',
        // text: '',
      })
    },
  },
}
</script>
