var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('data-table',{attrs:{"url":"/contradictions","resolve-data":function (data) { return (data && data.data && data.data.data) || []; },"columns":_vm.columns,"on-update-click":function (row) {},"delete-url":"/","more-table-actions":[
      {
        icon: 'RefreshCcwIcon',
        label: 'Auto-Generate contradiction point',
        onClick: _vm.autoGenerateContradictionPoints
      },
      {
        icon: 'Edit2Icon',
        label: 'Edit reduction amount',
        onClick: function () {
          _vm.isReductionAmountModalOpen = true
        }
      },
      {
        icon: 'FilePlusIcon',
        label: 'Add contradiction point',
        onClick: function () {
          _vm.isCreateContradictionPointModalOpen = true
        }
      } ]},scopedSlots:_vm._u([{key:"beforeTableHead",fn:function(){return [_c('b-alert',{staticClass:"p-1",attrs:{"show":"","variant":"warning"}},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"16px"}},[_c('feather-icon',{attrs:{"icon":"AlertTriangleIcon","size":"18"}}),_c('span',[_vm._v(" Rent retention is possible according to the contract ")])],1)])]},proxy:true}])}),_c('modal',{attrs:{"open":_vm.isReductionAmountModalOpen,"bread-crumb":[
      {
        text: 'Edit reduction amount',
        active: true,
      }
    ]},on:{"update:open":function($event){_vm.isReductionAmountModalOpen=$event}}},[_c('auto-form',{attrs:{"fields":_vm.reductionAmountFields,"entity":_vm.reductionAmountEntity,"no-default-cols":"","title":"Edit reduction amount","description":"Change if you want to update the reduction amount values"}})],1),_c('modal',{attrs:{"open":_vm.isCreateContradictionPointModalOpen,"bread-crumb":[
      {
        text: 'Add contraction point',
        active: true,
      }
    ]},on:{"update:open":function($event){_vm.isCreateContradictionPointModalOpen=$event}}},[_c('auto-form',{attrs:{"fields":_vm.addContradictionPointFields,"entity":_vm.contradictionPointEntity,"no-default-cols":"","title":"Add a new contradiction","description":"Contradiction point are used to contest an invoice"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }