/**
 * This object represents fields that should be shown
 * when there is no option selected in `document type` field
 * on the ContradictionPointForm component
 */
const contradictionOrConsequentContradictionFields = [
  {
    field: {
      key: 'fire_on',
      type: 'boolean',
      inline: true,
    },
  },
  {
    field: {
      key: 'contact_person_id',
    },
  },
  {
    field: {
      key: 'motivation_letter_preview',
      type: 'file',
    },
  },
  {
    field: {
      key: 'language',
      type: 'custom-select',
      items: [
        {
          label: 'Deutsch',
          value: 0,
        },
        {
          label: 'French',
          value: 1,
        },
        {
          label: 'English',
          value: 2,
        },
      ],
    },
  },
  {
    // White space
  },
  {
    props: { cols: 12 },
    field: {
      key: 'subject',
    },
  },
  {
    props: { cols: 12, style: 'min-height: 100px' },
    field: {
      key: 'motivation_letter_text',
      type: 'textarea',
    },
  },
  {
    field: {
      key: 'left_signer',
    },
  },
  {
    field: {
      key: 'right_signer',
    },
  },
  {
    field: {
      key: 'left_signer_role',
    },
  },
  {
    field: {
      key: 'main_signer_role',
    },
  },
  {
    props: { cols: 12, style: 'min-height: 100px' },
    field: {
      key: 'comment_field',
      type: 'textarea',
    },
  },
  {
    field: {
      key: 'next_action',
      type: 'custom-select',
      items: [
        {
          label: 'Injection download protocol',
          value: 0,
        },
        {
          label: 'Determine next action',
          value: 1,
        },
      ],
    },
  },
  {
    // White space
  },
  {
    field: {
      key: 'objection_attachments',
      type: 'file',
    },
  },
  {
    // White space
  },
  {
    field: {
      key: 'sfm_out',
      type: 'custom-select',
      items: [
        {
          label: 'Injection download protocol',
          value: 0,
        },
        {
          label: 'Determine next action',
          value: 1,
        },
      ],
    },
  },
  {
    field: {
      key: 'cancel_exam',
      type: 'boolean',
    },
  },
]

export default contradictionOrConsequentContradictionFields
