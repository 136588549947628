const addContradictionPointFields = [
  {
    field: {
      key: 'fire_on',
      type: 'boolean',
      inline: true,
    },
  },
  {
    field: {
      key: 'contact_person_id',
    },
  },
  {
    field: {
      key: 'contradiction_preview',
      type: 'file',
    },
  },
  {
    field: {
      key: 'attachments',
      type: 'file',
    },
  },
]

export default addContradictionPointFields
