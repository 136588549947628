const reductionAmountFields = [
  {
    field: {
      key: 'fire_on',
      type: 'boolean',
      inline: true,
    },
  },
  {
    field: {
      key: 'contact_person_id',
    },
  },
  {
    field: {
      key: 'reduction_id',
    },
  },
  {
    field: {
      key: 'no_reduction_id',
    },
  },
]

export default reductionAmountFields
