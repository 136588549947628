<template>
  <div>
    <div v-if="title || description">
      <h3 v-if="title">
        {{ $tc(title) }}
      </h3>
      <small v-if="description">{{ $tc(description) }}</small>
    </div>
    <hr v-if="title || description" class="mb-2">
    <validation-observer>
      <b-form>
        <b-row>
          <b-col
            v-for="(col, index) in fields"
            :key="`auto-form-${col.field ? col.field.key : index}${index}`"
            v-bind="{
              ...(col.props || {}),
              ...((noDefaultCols ? { cols: 12 } : col.props && col.props.cols ? col.props.cols : { cols: 6, md: 6 })),
              ...(globalColsProps || {}),
            }"

          >
            <!--
              Used to force component to rerender
              @value-change="onAutoCompleteValueChange"
            -->
            <auto-complete
              v-if="col.type === 'autocomplete' && col.field"
              v-bind="col.props || {}"
              :entity="entity"
              :prop-key="col.field.key"
              @autocomplete-change="autoCompleteChange"
            />
            <field
              v-else-if="col.field"
              v-bind="col.props || {}"
              :entity="entity"
              :field="col.field"
            />
            <small v-if="col && col.helper" class="text-secondary">{{ $tc(col.helper) }}</small>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import Field from '@/views/app/Generic/Field'
import AutoComplete from '../AutoComplete/AutoComplete.vue'

export default {
  components: {
    Field,
    AutoComplete,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    fields: {
      type: Array,
      required: true,
    },
    noDefaultCols: {
      type: Boolean,
      default: false,
    },
    globalColsProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    variable: null,
  }),
  methods: {
    autoCompleteChange(val) {
      this.$emit('autocomplete-change', val)
    },
  },
}
</script>

<style>
.form-group {
  margin: 0 !important;
}
</style>
