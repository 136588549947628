var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"autoCompleteRef",staticClass:"position-relative",style:({
    pointerEvents: _vm.disabled ? 'none' : undefined,
    opacity: _vm.disabled ? 0.5 : undefined,
    maxWidth: _vm.currentOption ? 'calc(100% - 10px)' : undefined
  })},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"8px"}},[_c('button',{staticClass:"form-control py-0 autocomplete-input-container d-flex align-items-center",staticStyle:{"gap":"8px"},attrs:{"type":"button","onclick":"this.querySelector('input').focus()"}},[_c('feather-icon',{staticClass:"flex-shrink-0",attrs:{"size":"16","icon":"SearchIcon"}}),(!_vm.showInput)?_c('div',{staticClass:"text-left text-truncate w-100"},[_vm._v(" "+_vm._s(_vm.currentOption && _vm.getOptionValue(_vm.currentOption) === (_vm.entity ? _vm.entity[_vm.propKey] : _vm.value) ? _vm.getOptionLabel(_vm.currentOption) : "No data selected")+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"autocomplete-input overflow-hidden",style:({ width: _vm.showInput ? undefined : 0 }),domProps:{"value":(_vm.inputValue)},on:{"focus":_vm.showOptions,"blur":_vm.hideOptions,"input":function($event){if($event.target.composing){ return; }_vm.inputValue=$event.target.value}}}),(_vm.loading)?_c('b-spinner',{staticClass:"flex-shrink-0",staticStyle:{"height":"12px","width":"12px"}}):_vm._e()],1),(_vm.currentOption)?_c('feather-icon',{staticClass:"flex-shrink-0",attrs:{"size":"16","icon":"XIcon"},on:{"click":function($event){_vm.$emit('update:value', undefined); _vm.$emit('valueChange', undefined); _vm.currentOption=null; _vm.onChange(undefined);}}}):_vm._e()],1),(_vm.teleport)?_c('Teleport',{attrs:{"to":"body"}},[_c('div',{staticClass:"position-fixed",style:({
        left: ((_vm.rect.left) + "px"),
        top: ((_vm.rect.top + _vm.rect.height + 5) + "px"),
        width: ((_vm.rect.width + 20) + "px"),
        pointerEvents: _vm.showOptionsList ? 'auto' : 'none',
        opacity: _vm.showOptionsList ? 1 : 0,
        zIndex: 100,
      })},[(_vm.options.length === 0)?_c('div',{staticClass:"shadow-sm d-flex flex-column justify-content-center align-items-center bg-white rounded-lg border py-2 px-1"},[_c('feather-icon',{attrs:{"size":"24","icon":"SearchIcon"}}),_c('p',{staticClass:"mt-50"},[_vm._v(" No data avalaible ")])],1):_c('ul',{staticClass:"autocomplete-list shadow-sm rounded-lg"},_vm._l((Array.isArray(_vm.options) ? _vm.options : []),function(option,index){return _c('li',{key:_vm.getOptionLabel(option) + _vm.getOptionValue(option) + index},[_c('button',{staticClass:"autocomplete-option",attrs:{"type":"button"},on:{"mousedown":function($event){return _vm.onOptionSelected(option)}}},[_vm._v(" "+_vm._s(_vm.getOptionLabel(option))+" ")])])}),0)])]):_c('div',{staticClass:"position-absolute",style:({
      left: "0",
      top: "calc(100% + 10px)",
      width: ((_vm.rect.width) + "px"),
      pointerEvents: _vm.showOptionsList ? 'auto' : 'none',
      opacity: _vm.showOptionsList ? 1 : 0,
      zIndex: 100,
    })},[(_vm.options.length === 0)?_c('div',{staticClass:"shadow-sm d-flex flex-column justify-content-center align-items-center bg-white rounded-lg border py-2 px-1"},[_c('feather-icon',{attrs:{"size":"24","icon":"SearchIcon"}}),_c('p',{staticClass:"mt-50"},[_vm._v(" No data avalaible ")])],1):_c('ul',{staticClass:"autocomplete-list shadow-sm rounded-lg"},_vm._l((Array.isArray(_vm.options) ? _vm.options : []),function(option,index){return _c('li',{key:_vm.getOptionLabel(option) + _vm.getOptionValue(option) + index},[_c('button',{staticClass:"autocomplete-option",attrs:{"type":"button"},on:{"mousedown":function($event){return _vm.onOptionSelected(option)}}},[_vm._v(" "+_vm._s(_vm.getOptionLabel(option))+" ")])])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }