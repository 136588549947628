<template>
  <b-card>
    <div>
      <h3>More options</h3>
      <small>Add more options to the document</small>
    </div>
    <hr class="mb-2">
    <validation-observer>
      <b-form>
        <b-row>
          <b-col cols="6" md="6">
            <field
              :entity="entity"
              :field="firstSelectField"
            />
          </b-col>
          <b-col
            v-for="(col, index) in currentFields"
            :key="`contradiction-point-form-${col.field ? col.field.key : index}${index}`"
            v-bind="col.props || { cols: 6, md: 6 }"

          >
            <field
              v-if="col.field"
              :entity="entity"
              :field="col.field"
            />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import Field from '@/views/app/Generic/Field'
import noChoiceFields from './fields/noChoiceFields'
import projectProtocolFields from './fields/projectProtocolFields'
import contradictionOrConsequentContradictionFields from './fields/contradictionOrConsequentContradictionFields'
import protocolFields from './fields/protocolFields'

const firstSelectField = {
  key: 'documentype_id',
  type: 'custom-select',
  items: [
    {
      label: 'Choose please',
      value: 0,
      fieldsToShowOnForm: noChoiceFields,
    },
    {
      label: 'Contradiction/consequence',
      value: 1,
      fieldsToShowOnForm: contradictionOrConsequentContradictionFields,
    },
    {
      label: 'Protocol',
      value: 2,
      fieldsToShowOnForm: protocolFields,
    },
    {
      label: 'Project protocol',
      value: 3,
      fieldsToShowOnForm: projectProtocolFields,
    },
  ],
}

const entity = {
  tc: '',
}

export default {
  components: {
    Field,
  },
  data() {
    return {
      entity,
      firstSelectField,
      currentFields: firstSelectField.items[0].fieldsToShowOnForm,
    }
  },
  watch: {
    'entity.documentype_id': function (val) {
      this.currentFields = firstSelectField.items.find(opt => val === opt.value)?.fieldsToShowOnForm || noChoiceFields
    },
  },
}
</script>
