/**
 * This object represents fields that should be shown
 * when there is no option selected in `document type` field
 * on the ContradictionPointForm component
 */
const noChoiceFields = [
  {
    field: {
      key: 'fire_on',
      type: 'boolean',
      inline: true,
    },
  },
  {
    field: {
      key: 'contact_person_id',
    },
  },
  {
    field: {
      key: 'contradiction_preview',
      type: 'file',
    },
  },
  {
    field: {
      key: 'attachments',
      type: 'file',
    },
  },
  {
    field: {
      key: 'sfm_out',
      type: 'list',
      list: 'customergroup',
      listLabel: 'customergroup_name',
    },
  },
  {
    field: {
      key: 'cancel_exam',
      type: 'boolean',
      inline: true,
    },
  },
]

export default noChoiceFields
