const columns = [
  {
    id: 'contract_id',
    key: 'contract_id',
    disabled: true,
    hideActions: true,
    header: {
      name: 'Id',
    },
  },
  {
    id: 'contract_name',
    key: 'contract_name',
    header: {
      name: 'Name',
    },
    props: {
      bold: true,
    },
  },
  {
    id: 'location_name',
    key: 'location_name',
    header: {
      name: 'Location',
      icon: 'MapPinIcon',
    },
    props: {
      // style: 'min-width: 250px; max-width: 250px',
    },
  },
  {
    id: 'contracttype_name',
    key: 'contracttype_name',
    header: {
      name: 'Contract Type',
    },
  },
]

export default columns
