<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <div class="d-flex align-items-center">
        <div/>
        <!-- <img class="mr-1" src="@/assets/images/icons/people.svg" alt=""> -->
        <!-- <h2>{{ $t(title) }}</h2> -->
        <h2>{{ entity ? entity.contradiction_name : "" }} <span v-if="entity && entity.contradiction_id" class="text-primary">#{{ entity.contradiction_id }}</span></h2>
      </div>
      <div class="d-flex align-items-center">
        <notes v-if="definition.note" :id="entityId" class="mr-2" :primary-key="primaryKey" :note="definition.note"
               :note-rel="'note_user_'+table+'_rel'"/>
        <b-button v-if="view" variant="info" class="mr-1" @click="edit">
          <feather-icon icon="EditIcon" class="mr-50"/>
          {{ $t('Edit') }}
        </b-button>
        <b-button v-else variant="success" class="mr-1" :disabled="loading" @click="update">
          <b-spinner v-if="loading" small class="mr-50"/>
          <feather-icon v-else icon="CheckIcon" class="mr-50"/>
          {{ $t('Save') }}
        </b-button>
        <b-button v-if="!view" variant="primary" @click="cancel">
          <feather-icon icon="XIcon" class="mr-50"/>
          {{ $t('Cancel') }}
        </b-button>

      </div>
    </div>

    <b-tabs v-if="!create" ref="tabs" v-model="activeTab">
      <!-- <b-tab title="Contradiction Points" active>
        <contradiction-point ref="points" @go-to-reductions="activeTab=1" @items="updateItems"/>
      </b-tab> -->
      <b-tab title="Inspection result" lazy>
        <b-card class="mb-1">
          <!-- <div class="d-flex justify-content-end" style="padding: 7px">
            <b-button :disabled="saving" variant="primary" @click="saveReductions">
              <b-spinner v-if="saving" class="mr-1" small/>
              <span>{{ $t('button~save') }}</span>
            </b-button>
          </div> -->
          <div>
            <h3>Result</h3>
            <small>Result description</small>
            <hr>
          </div>
          <b-row>
            <b-col v-for="x in [1, 2, 3]" :key="x">
              <table style="border-collapse: separate; border-spacing: 8px 0;">
                <tbody>
                  <tr v-for="y in [1, 2, 3]" :key="y">
                    <td class="font-weight-bold">
                      data 1
                    </td>
                    <td>value 1</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </b-card>
        <!-- <b-card>
          <div v-for="(item,i) in activeItems" :key="i" class="mb-3">
            <h4>{{ item.contradictionpoint_rank + ' - ' + item.contradictionpoint_title }}</h4>
            <reduction-amount :entity="item" :disabled="saving"/>
          </div>
        </b-card> -->

      </b-tab>
      <b-tab title="Contract" lazy >
        <b-card class="mb-1">
          <div>
            <h3>Contract</h3>
            <small>Contract description</small>
            <hr>
          </div>
          <b-row>
            <b-col v-for="x in [1, 2, 3]" :key="x">
              <table style="border-collapse: separate; border-spacing: 8px 0;">
                <tbody>
                  <tr v-for="y in [1, 2, 3]" :key="y">
                    <td class="font-weight-bold">
                      data 1
                    </td>
                    <td>value 1</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
      <b-tab title="Partial Invoice" lazy>
        <b-card class="mb-1">
          <div>
            <h3>Partial Invoice</h3>
            <small>Partial Invoice description</small>
            <hr>
          </div>
          <b-row>
            <b-col v-for="x in [1, 2, 3]" :key="x">
              <table style="border-collapse: separate; border-spacing: 8px 0;">
                <tbody>
                  <tr v-for="y in [1, 2, 3]" :key="y">
                    <td class="font-weight-bold">
                      data 1
                    </td>
                    <td>value 1</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
      <b-tab title="Documents and communication" lazy>
        <correspondance-form/>
      </b-tab>
      <b-tab title="Preview" lazy/>
    </b-tabs>
    <div>
      <b-card>
        <small class="d-block mb-1 text-secondary">Information about the contradiction</small>
        <contradiction-form ref="form" :table="table" :definition="definition" :table-definition-key="table"
                            :create="create" :is-relation="false" :disabled="view" :inline="false" :cols="6"
                            :initial-data="entity" :entity-id="entityId"/>
      </b-card>
      <Contract />
      <ContradictionPointForm />
    </div>
  </div>
</template>

<script>
import EditPageMixin from '@/views/app/Generic/EditPageMixin'
import {
  BTabs, BTab, BCard, BButton, BSpinner,
} from 'bootstrap-vue'
import EntityForm from '@/views/app/Generic/EntityForm'
import ContradictionForm from '@/views/app/FormComponent/ContradictionForm'
import ContradictionPoint from '@/views/app/Page/Contradiction/ContradictionPoint'
import Notes from '@/views/app/Generic/Notes'
import ReductionAmount from '@/views/app/CreateComponent/ReductionAmount'
import { clone } from '@/libs/utils'
import Field from '@/views/app/Generic/Field'
import CorrespondanceForm from '@/views/app/Page/Contradiction/CorrespondanceForm'
import ContradictionPointForm from './ContradictionPointForm/Index'
import Contract from './Contract/index'

export default {
  name: 'Index',
  components: {
    CorrespondanceForm,
    Field,
    ReductionAmount,
    Notes,
    ContradictionPoint,
    ContradictionPointForm,
    ContradictionForm,
    BTab,
    BTabs,
    BCard,
    BButton,
    BSpinner,
    Contract,
  },
  mixins: [EditPageMixin],
  data() {
    return {
      activeTab: 0,
      saving: false,
      activeItems: [],
    }
  },
  methods: {
    updateItems(items) {
      console.log('on update', items)
      this.activeItems = clone(items)
    },
    saveReductions() {
      if (this.saving) return
      this.saving = true
      this.$api({
        action: 'update',
        entity: 'contradictionpoint',
        data: this.activeItems,
      })
        .then(() => {
          this.$store.commit('table/deleteTableCacheKeyFromPrefix', 'contradictionpoint-')
          this.$refs.points.$refs.table.reload()
          this.$successToast('Reduction amounts Saved')
        })
        .catch(e => {
          console.error(e)
          this.$errorToast(e.message)
        })
        .finally(() => {
          this.saving = false
        })
    },
  },
}
</script>

<style scoped>

</style>
